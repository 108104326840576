<template>
  <section class="footer__section">
    <div class="content__area">
      <h2 class="footer__title">Weitere interessante Themen</h2>
      <ul class="footer__list">
        <li v-for="link in links" :key="link.url" class="footer__item">
          <router-link class="footer__link" :to="link.url">
            {{ link.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterSEOLinks',
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__section {
  padding: 1rem 0;
  background-color: var(--background-tertiary);

  .footer__link,
  .footer__item,
  .footer__title {
    font-size: 0.9rem;
    color: var(--root-color);
    text-decoration: none;
  }

  .footer__title {
    @include font-normal;

    margin: 5px 20px 0 0;
    text-align: center;
  }

  .footer__item {
    position: relative;
    margin: 5px 20px 5px 0;
    list-style: none;
  }

  @include breakpoint($up-to-tablet) {
    .footer__item,
    .footer__title {
      margin-right: 10px;
    }
  }
}

.footer__list {
  padding: 5px 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
}
</style>

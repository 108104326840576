<template>
  <footer
    v-if="!appmode"
    :aria-labelledby="footerId"
    class="footer"
    data-cy="footer"
  >
    <span :id="footerId" aria-hidden="true" class="visually-hidden">
      Footer
    </span>

    <SEOLinks v-if="filteredSeoLinks" :links="filteredSeoLinks" />
    <div class="footer--box-shadow">
      <GoTop v-if="showGoTop" />
      <Trustelements
        v-if="trustelements && $brand.isFonic()"
        ref="trustelements"
      />
      <Legal v-if="legal" />
      <Links :show-links="links" />
      <Imprint :social-networks="socialNetworks" />
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { uniqueId } from 'src/lib/helpers'
import GoTop from './go_top.vue'
import Imprint from './imprint.vue'
import Legal from './legal.vue'
import Links from './links.vue'
import SEOLinks from './seo_links.vue'
import Trustelements from './trustelements.vue'
import seoLinksCollection from './seo_links.js'

export default {
  name: 'FooterComponent',
  components: {
    GoTop,
    Legal,
    Links,
    Imprint,
    Trustelements,
    SEOLinks
  },
  props: {
    showGoTop: {
      type: Boolean,
      default: false
    },
    trustelements: {
      type: Boolean,
      default: false
    },
    socialNetworks: {
      type: Boolean,
      default: false
    },
    legal: {
      type: Boolean,
      default: false
    },
    links: {
      type: Boolean,
      default: false
    },
    seoLinks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      footerId: 'footer-' + uniqueId()
    }
  },
  computed: {
    ...mapState({
      appmode: state => (state.authentication.appmode ? true : false)
    }),
    filteredSeoLinks() {
      return this.seoLinks && this.$brand.isFonic()
        ? seoLinksCollection[this.$route.path]
        : undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  color: $footer-color;
  background-color: var(--footer-background);

  @include fonic-mobile {
    color: var(--root-color);
  }

  .footer--box-shadow {
    box-shadow: 0 -4px 5px 0 rgb(0 0 0 / 6%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);
  }
}

@include is-dark {
  .footer {
    .footer--box-shadow {
      box-shadow: 0 -4px 5px 0 rgb(255 255 255 / 6%),
        0 3px 1px -2px rgb(255 255 255 / 12%),
        0 1px 5px 0 rgb(255 255 255 / 20%);
    }
  }
}
</style>

<template>
  <div v-if="showBadge" class="trusted-shops-badge__wrapper">
    <TrustedShopsScript
      v-if="executeScript"
      :element-id="trustedShopsBadgeId"
      :is-loaded="scriptDidLoad"
    />
    <a
      class="trusted-shops__link"
      :href="`https://www.trustedshops.de/bewertung/info_${$brand.trustedShopsId}.html`"
      rel="noopener noreferrer"
      target="_blank"
    >
      <ImageComponent
        class="trusted-shops__image"
        width="90"
        height="90"
        alt="Logo Garantiesiegel Trusted Shops"
        :src="trustedShopsImg"
      />

      <div class="trustelements__text">
        <p class="slim">Qualität und Sicherheit</p>
        <p class="slim">Mit dem Käuferschutz von Trusted Shops</p>
      </div>
    </a>

    <div
      v-show="showTrustedShopsMenu"
      :id="trustedShopsBadgeId"
      ref="trustedShopBadge"
      class="trusted-shops-badge"
      :aria-hidden="showTrustedShopsMenu"
      :class="{ 'error-state': trustedShopsLoadError }"
    ></div>
  </div>
</template>

<script>
import { openInNewWindow, uniqueId } from 'src/lib/helpers'
import ImageComponent from 'src/components/common/image/image.vue'
import TrustedShopsScript from './trustedshops_script.vue'
import img from './resources/trusted_shops_transparent.webp'

export default {
  name: 'TrustedShopsBadge',
  components: {
    TrustedShopsScript,
    ImageComponent
  },
  data() {
    return {
      queryInterval: undefined,
      queryCheckCounter: 0,
      scriptLoaded: false,
      trustedShopsBadgeId: 'trustedShopsBadge-' + uniqueId(),
      executeScript: false,
      showBadge: true,
      delayReloadBy: window.Cypress ? 0 : 3000,
      trustedShopsLoadError: false,
      showTrustedShopsMenu: false,
      trustedShopsImg: img
    }
  },
  watch: {
    $route() {
      this.reloadScript()
    }
  },
  mounted() {
    this.reloadScript()
    this.$bus.on('ts-badge-click', this.toggleTrustedShopsCard)
    this.$bus.on('ts-badge-close', this.close)
    this.$bus.on('trustedshops-load-error', this.onLoadError)
  },
  beforeUnmount() {
    this.$bus.off('ts-badge-click', this.toggleTrustedShopsCard)
    this.$bus.off('ts-badge-close', this.close)
    this.$bus.off('trustedshops-load-error', this.onLoadError)
  },
  methods: {
    toggleTrustedShopsCard(event) {
      const isSvg = event.target.tagName.toLowerCase() === 'svg'

      if (!isSvg) {
        const isMobileCloseButton =
          event.target.className.indexOf('ts-badge__mobile__close') > -1
        const isDesktopCloseButton =
          event.target.className.indexOf('ts-badge__desktop__close') > -1

        if (isMobileCloseButton || isDesktopCloseButton) {
          return
        }
      }

      const trustedShopsLogo = this.$refs.trustedShopBadge.querySelector('img')

      if (trustedShopsLogo) {
        trustedShopsLogo.click()
      } else {
        openInNewWindow(
          `https://www.trustedshops.de/bewertung/info_${this.$brand.trustedShopsId}.html`
        )
      }
    },
    close() {
      this.showBadge = false
      // If the card is open, make sure it is closed as well
      const card = document.querySelector(
        'div[id^=trustbadge-container-custom-maximized-]'
      )

      if (card) {
        card.remove()
      }
    },
    onLoadError() {
      this.trustedShopsLoadError = true
    },
    scriptDidLoad() {
      this.scriptLoaded = true
    },
    reloadScript() {
      this.showTrustedShopsMenu = this.$route.path === '/verifikation/auswahl'

      this.scriptLoaded = false
      this.executeScript = false

      if (this.$refs.trustedShopBadge) {
        this.$refs.trustedShopBadge.innerHTML = ''
      }

      setTimeout(() => (this.executeScript = true), this.delayReloadBy)
    }
  }
}
</script>

<style lang="scss">
div[id^='tsbadgeResponsiveTop_'] {
  position: absolute;
  visibility: hidden !important;
  height: 0;
}

div[id^='MobileCoveringLayer_'] {
  position: fixed !important;
}
</style>

<style lang="scss">
.trusted-shops-badge {
  img {
    width: 50px !important;
    height: 50px !important;

    @include breakpoint($up-to-three-columns) {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.trusted-shops-badge {
  position: fixed;
  right: 38px;
  bottom: 105px;
  z-index: 1000;
  margin-left: -150px;
  margin-top: -203px;
  height: 50px;
  width: 50px;

  &.error-state {
    bottom: 61px;
  }

  @include breakpoint($up-to-three-columns) {
    left: 157px;
    top: 206px;
    width: 25px;
    height: 25px;
  }
}

.trusted-shops__link {
  color: var(--root-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.trusted-shops__image {
  max-height: 90px;
  min-width: 90px;

  img {
    max-height: inherit;
  }
}
</style>

<template>
  <div class="footer__section">
    <div class="content__area">
      <a class="footer__link footer__link--goto" @click.prevent="scrollUp">
        <Icon class="goto-top-icon" name="arrow-right" height="9" />
        Zurück zum Seitenanfang</a
      >
    </div>
  </div>
</template>

<script>
import { scrollTop } from 'src/lib/scroll_to.js'
import Icon from '../icon/icon.vue'

export default {
  name: 'FooterGoTop',
  components: { Icon },
  methods: {
    scrollUp() {
      scrollTop()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__section {
  padding: 1rem 0;
  text-align: center;
  color: inherit;
  background-color: var(--background-secondary);

  @include fonic-mobile {
    border-bottom: 2px solid $c_tropic-blue;
  }

  @include breakpoint($from-desktop) {
    display: none;
  }
}

.footer__link {
  color: inherit;
  text-decoration: none;
}

.footer__link--goto {
  color: $footer__link--goto-color;
  font-weight: bold;
}

.goto-top-icon {
  position: relative;
  top: -2px;
  transform: rotate(-90deg);
  margin-right: 8px;
  color: $c-primary;
}
</style>

<template>
  <div class="legal__container">
    <div class="legal__component">
      <div class="legal__item">
        <strong>Ihr Einverständnis vorausgesetzt:</strong>
        {{ $brand.name }} wird Ihre für die Begründung, inhaltliche
        Ausgestaltung, Änderung oder Beendigung des Vertragsverhältnisses
        erhobenen personenbezogenen Daten <strong>(Bestandsdaten)</strong> im
        Rahmen des Erforderlichen gemäß den gesetzlichen Bestimmungen auch für
        die <strong>Vermarktung</strong> und
        <strong
          >bedarfsgerechte Gestaltung von Telekommunikations- und
          Telemediendiensten</strong
        >
        von <strong>{{ $brand.name }}</strong> sowie für
        <strong>Marktforschung</strong> verwenden und um Ihnen
        <strong>Produkte von {{ $brand.name }}</strong> per
        <strong>E-Mail, SMS/MMS</strong> und
        <strong>Brief zu empfehlen</strong>, die Ihrem Vertragsprodukt ähnlich
        sind. Sie können der Verwendung Ihrer personenbezogenen Daten zu diesen
        Zwecken jederzeit <strong>ganz oder teilweise</strong> durch eine
        einfache Nachricht an Telefónica Germany GmbH &amp; Co. OHG,
        Postanschrift: {{ $brand.name }} Kundenbetreuung, Postfach 1038, 90001
        Nürnberg oder an&nbsp;
        <a class="footer__link" href="mailto:service@fonic.de"
          >service@fonic.de</a
        >
        <strong> widersprechen</strong>, ohne dass hierfür andere als die
        <b>Übermittlungskosten nach den Basistarifen</b> anfallen.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLegal'
}
</script>

<style lang="scss" scoped>
.legal__container {
  width: 100%;
  background-color: #dae1e5;
  color: #2f4e63;
  font-size: 0.7rem;
  padding: 20px 0;
}

.legal__component {
  max-width: $three-columns;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.legal__item {
  margin-left: 10px;

  &::before {
    content: '*';
    position: relative;
    left: -10px;
    top: 4px;
    font-size: 0.9rem;
  }
}
</style>

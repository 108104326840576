<script>
import { uniqueId } from 'src/lib/helpers'

export default {
  name: 'TrustedShopsScript',
  props: {
    elementId: {
      type: String,
      required: true
    },
    isLoaded: {
      type: Function,
      required: true
    }
  },
  setup() {
    return () => {}
  },
  data() {
    return {
      srcPath:
        '//widgets.trustedshops.com/js/' + this.$brand.trustedShopsId + '.js',
      loadRemoteScript: this.$tracking.isTrackingEnabled,
      script: undefined
    }
  },
  created() {
    window._tsConfig = {
      yOffset: '0' /* offset from page bottom */,
      variant:
        'custom' /* text, default, small, reviews, custom, custom_reviews */,
      customElementId:
        this.elementId /* required for variants custom and custom_reviews */,
      trustcardDirection:
        'topLeft' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
      customBadgeWidth: '0' /* for custom variants: 40 - 90 (in pixels) */,
      customBadgeHeight: '90' /* for custom variants: 40 - 90 (in pixels) */,
      disableResponsive: 'true' /* deactivate responsive behaviour */,
      disableTrustbadge: 'false' /* deactivate trustbadge */
    }
  },
  mounted() {
    this.attachTSScript()
  },
  beforeUnmount() {
    this.removeTSScript()
  },
  methods: {
    attachTSScript() {
      if (!window._tsIsAttached) {
        if (this.loadRemoteScript) {
          window._tsIsAttached = true
          this.script = document.createElement('script')
          this.script.async = true
          this.script.id = `ts-script-id-${uniqueId()}`
          this.script.type = 'application/javascript'
          this.script.src = this.srcPath
          this.script.onload = this.isLoaded

          document.head.appendChild(this.script)
        }
      }
    },
    removeTSScript() {
      if (window.trustbadge) {
        window.trustbadge.remove()
      }

      if (this.script) {
        this.script.remove()
      }

      this.script = undefined
      window._tsIsAttached = undefined
      window._tsConfig = undefined
    }
  }
}
</script>

<template>
  <div class="trustelements__container">
    <Modal
      ref="trustmodal"
      disable-link
      title="FONIC auf Platz 1 im Bereich Kundenservice"
    >
      <div class="trustmodal">
        <ImageComponent
          class="trustmodal__image--background"
          :src="trustelementLarge"
        />
        <div>
          <p class="description">
            Wir sind sehr stolz darauf erneut eine Top-Platzierung im Bereich
            Kundenservice zu kriegen. 12 Mobilfunk-Discounter wurden dabei von
            der Zeitung „Die Welt“ untersucht und dabei FONIC als
            „Service-Champions“ ausgezeichnet. Weitere Testergebnisse und
            Tarifvergleiche zu FONIC finde Sie hier
            <a
              href="/handytarife-testergebnisse-tarifvergleiche-fonic-im-test"
              target="_blank"
              >hier.</a
            >
          </p>
          <a
            href="https://www.servicevalue.de/wettbewerbe/branchenuebergreifend/service-champions-deutschland/ranking/ranking-mobilfunk-discounter-2/"
            target="_blank"
            rel="noopener noreferrer"
            >Mehr Information über den Test gibt es unter www.servicevalue.de</a
          >
        </div>
      </div>
    </Modal>

    <div class="trustelements__component">
      <ButtonComponent
        button-type="look-like-link"
        :clickhandler="openTrustmodal"
      >
        <div class="trustelements__item">
          <ImageComponent
            class="trustelements__image"
            :src="trustelement"
            width="77.67"
            height="90"
            alt="Logo Auszeichnung Die Welt Service-Champions"
          />

          <div class="trustelements__text">
            <p class="slim">Ausgezeichneter Service</p>
            <p class="slim">
              Platz 1 für {{ $brand.name }} im Bereich Kundenservice
            </p>
          </div>
        </div>
      </ButtonComponent>

      <div class="trustelements__spacer"></div>

      <TrustedShopsBadge class="trustelements__item" />
    </div>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import ImageComponent from 'src/components/common/image/image.vue'
import Modal from 'src/components/common/modal/modal.vue'
import TrustedShopsBadge from 'src/components/common/trustedshops/trustedshops_badge.vue'
import trustelement from './resources/trustelement.webp'
import trustelementLarge from './resources/trustelement--large.webp'

export default {
  name: 'FooterTrustelements',
  components: {
    TrustedShopsBadge,
    Modal,
    ButtonComponent,
    ImageComponent
  },
  data() {
    return {
      trustelement,
      trustelementLarge
    }
  },
  methods: {
    openTrustmodal() {
      this.$refs.trustmodal.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/order/variables';

.trustelements__container {
  background-color: var(--footer-background);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.trustelements__component {
  width: $three-columns;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;

  @include breakpoint($from-desktop) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.trustelements__spacer {
  width: 100%;
  height: 2px;
  background-color: white;

  @include breakpoint($from-desktop) {
    display: none;
  }
}

.trustelements__item {
  display: flex;
  margin: 15px 35px;
  min-height: 105px;
  justify-content: center;
  align-items: center;
  color: $footer-color;

  :deep(.button-label) {
    display: flex;
  }

  @include breakpoint($from-desktop) {
    margin: 10px;
  }

  .trustelements__image {
    max-height: 90px;
    min-width: 90px;

    img {
      max-height: inherit;
    }
  }

  :deep(.trustelements__text) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    flex-shrink: 1;
    color: $footer-color;

    @include breakpoint(500px) {
      width: 320px;
    }

    p:first-child {
      @include font-normal;
    }
  }
}

.trustmodal {
  display: flex;
  flex-direction: column;

  .description {
    margin-bottom: 16px;
  }
}

.trustmodal__image--background {
  height: 190px;
  width: 164px;
  align-self: center;
  margin-bottom: 20px;
}
</style>
